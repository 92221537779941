import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faGithub,
} from '@fortawesome/free-brands-svg-icons'
import GatsbyLink from 'gatsby-link';

function Layout({ children }) {

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white pb-8">

            {/* Header */}
            <nav>
                <div className="container mx-auto px-8 py-6 flex justify-between items-center">
                    <GatsbyLink to={"/"} className="text-2xl lg:text-4xl">
                        <span className="font-bold">terry</span><span className="font-thin">butler</span>
                    </GatsbyLink>

                    {/* 
                    <div className="block lg:hidden">
                        <button className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none" aria-controls="mobile-menu" aria-expanded="false">
                            <svg
                                className="fill-current h-3 w-3"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div> 
                    */}

                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-end">
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                <GatsbyLink to={"/"} className="px-4 hover:text-gray-800" activeClassName={"font-bold"}>Home</GatsbyLink>
                                <GatsbyLink to={"/articles"} className="px-4 hover:text-gray-800" activeClassName={"font-bold"}>Articles</GatsbyLink>
                                {/* TODO TERRY: <GatsbyLink to={"/about"} className="px-4 hover:text-gray-800" activeClassName={"font-bold"}>About</GatsbyLink> */}
                                <GatsbyLink to={"/contact"} className="px-4 hover:text-gray-800" activeClassName={"font-bold"}>Contact</GatsbyLink>
                                <a className="px-4 hover:text-gray-800" href="https://github.com/terryjbutler/" target="_blank">
                                    <FontAwesomeIcon icon={faGithub} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sm:hidden" id="mobile-menu">
                    <div className="px-8 pb-3 space-y-1">
                        <GatsbyLink to={"/"} className="block px-3 py-2 rounded-md text-base font-medium" activeClassName={"bg-white bg-opacity-50 text-gray-800"}>Home</GatsbyLink>
                        <GatsbyLink to={"/articles"} className="block px-3 py-2 rounded-md text-base font-medium" activeClassName={"bg-white bg-opacity-50 text-gray-800"}>Articles</GatsbyLink>
                        {/* <GatsbyLink to={"/about"} className="block px-3 py-2 rounded-md text-base font-medium" activeClassName={"bg-white bg-opacity-50 text-gray-800"}>About</GatsbyLink> */}
                        <GatsbyLink to={"/contact"} className="block px-3 py-2 rounded-md text-base font-medium" activeClassName={"bg-white bg-opacity-50 text-gray-800"}>Contact</GatsbyLink>
                        <a className="block px-3 py-2 rounded-md text-base font-medium" href="https://github.com/terryjbutler/" target="_blank">
                            <FontAwesomeIcon icon={faGithub} /> My GitHub
                        </a>
                    </div>
                </div>
            </nav>

            {/* Body */}
            <div className="container mx-auto rounded-xl overflow-hidden bg-white bg-opacity-30 p-8">
                {children}
            </div>

            {/* Footer */}
            <div className="container mx-auto pt-4">

                <div className="flex items-center justify-center space-x-4">
                    <a href="https://www.gatsbyjs.com/" target="_blank">
                        <img src="/images/logos/gatsby.svg" alt="Gatsby" className="flex-initial h-8" />
                    </a>
                    <a href="https://www.netlify.com/" target="_blank">
                        <img src="/images/logos/netlify.svg" alt="Netlify" className="flex-initial h-8" />
                    </a>
                    <a href="https://reactjs.org/" target="_blank">
                        <img src="/images/logos/react.svg" alt="React" className="flex-initial h-8" />
                    </a>
                    <a href="https://www.typescriptlang.org/" target="_blank">
                        <img src="/images/logos/typescript.svg" alt="TypeScript" className="flex-initial h-8" />
                    </a>
                    <a href="https://tailwindcss.com/" target="_blank">
                        <img src="/images/logos/tailwindcss.svg" alt="Tailwind CSS" className="flex-initial h-8" />
                    </a>
                </div>

                <div className="flex justify-center pt-4">
                    © Copyright 2008-{new Date().getFullYear()} Terry Butler
                </div>
            </div>

        </div>
    );
}

export default Layout;